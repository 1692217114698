@import '~ethos-design-system/src/components/Media/Media.scss';

.trustPilot {
  margin-top: var(--Space-48);
  margin-bottom: var(--Space-48);

  &.noTitleMargin {
    margin-top: 0;
  }

  @include for-laptop-only {
    margin-top: var(--Space-64);
    margin-bottom: var(--Space-64);
  }

  @include for-desktop-only {
    margin-top: var(--Space-80);
    margin-bottom: var(--Space-80);
  }
}

.reviews {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: var(--Space-24);
  cursor: pointer;

  &.noTitleMargin {
    margin-top: 0;
  }

  @include for-laptop-and-up {
    margin-top: var(--Space-48);
  }
}

.meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: auto;
  padding-top: var(--Space-40);
}

.title {
  margin-bottom: var(--Space-8);
}

.review {
  .inner {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: solid 2px transparent;
    height: 100%;
  }

  &:hover,
  &:active {
    .inner {
      border-color: var(--BrandForest);
    }
  }

  &:nth-child(1),
  &:nth-child(4),
  &:nth-child(6),
  &:nth-child(9),
  &:nth-child(11) {
    background-color: #f5f3f0;
  }

  &:nth-child(2),
  &:nth-child(7),
  &:nth-child(12) {
    background-color: var(--BrandMoss);

    .title {
      color: var(--BrandForest);
    }

    .date,
    .body {
      color: #50817b;
    }
  }

  &:nth-child(3),
  &:nth-child(5),
  &:nth-child(8),
  &:nth-child(10) {
    background-color: var(--BrandDuckegg);
  }

  &.reviewRebrandColorTheme {
    border-radius: 15px;

    .title,
    .body {
      color: var(--GrayPrimary--opaque);
    }

    &:hover,
    &:active {
      .inner {
        border-radius: 15px;
      }
    }

    // If this treatment is baselined, these will need to follow the pattern above
    // account for additional potential children up the 12th
    &:nth-child(1) {
      background-color: #d9e7e6;
    }

    &:nth-child(2) {
      background-color: #f9ffdf;
    }

    &:nth-child(3) {
      background-color: #f5f4ec;
    }

    &:nth-child(4) {
      background-color: #f3f7f7;
    }
  }
}

.allLink {
  display: inline-block;
  margin-top: var(--Space-8);
  padding-bottom: var(--Space-8);
  border-bottom: solid 1px var(--GrayPrimary--opaque);
  &:hover,
  &:active {
    opacity: 0.85;
    border-color: var(--GrayPrimary--translucent);
  }
}

@include for-phone-only {
  .review {
    width: 100%;
    margin-bottom: var(--Space-16);

    // Only display one review on mobile
    &:nth-child(n + 2) {
      display: none;
    }
  }

  .bottom {
    display: block;
  }

  .allLink {
    margin-bottom: var(--Space-16);
  }
}

@include for-tablet-only {
  .review {
    width: calc(50% - var(--Space-8));
    margin-bottom: var(--Space-16);

    &:nth-child(n + 5) {
      display: none;
    }
  }
}

@include for-tablet-and-up {
  .bottom {
    display: flex;
    justify-content: space-between;
  }
}

@include for-laptop-only {
  .review {
    width: calc(33.33% - var(--Space-12));
    margin-bottom: var(--Space-24);

    &:nth-child(n + 4) {
      display: none;
    }
  }
}

@include for-desktop-only {
  .review {
    width: calc(25% - var(--Space-16));
    margin-bottom: var(--Space-24);
  }
}
