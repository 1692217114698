@import '~ethos-design-system/src/components/Media/Media.scss';

.trustPilotBanner {
  display: flex;
  justify-content: center;
  background: var(--GrayLightHover--opaque);
  padding-top: 5px;
  padding-bottom: 1px;
  @include for-tablet-and-up {
    padding-top: 9px;
    padding-bottom: 4px;
  }
}

.text {
  font-size: 12px;
  line-height: 1;
  padding-top: 3px;
}

.stars {
  margin: 0 var(--Space-8);
}
